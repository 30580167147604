.component-1 {
  box-shadow: none;
  font-size: 14px;
  color: #333333;

  // Hacks
  // ---------------------------------------------------
  .datatable-scroll {
    width: 100% !important;
  }

  datatable-footer select {
    border: none
  }

  // Header
  // ---------------------------------------------------
  .datatable-header {

    .datatable-header-cell {
      border-top: 1px solid #C0C0C0;
      border-bottom: 1px solid #C0C0C0;
      border-left: 1px solid #E0E0E0;

      padding: 16px 8px;
      height: 56px;

      &:first-child {
        border-left: 0;
      }

      .datatable-header-cell-label {
      }
    }
  }

  // Body
  // ---------------------------------------------------
  .datatable-body {
    .datatable-body-row {
      vertical-align: middle;
      background: #FFFFFF;
      /* Box-shadow */
      //border-radius: 8px;
      //margin: 4px 0;
      &.datatable-row-even {
        background: #ededed;
      }
      &.active {
        background-color: #D1D5D9;
        //color: #fff;
      }
      .datatable-body-cell {
        padding: 8px 8px;
        text-align: left;
        vertical-align: middle;
      }
    }
    .empty-row {
      $alert-padding-y: 0.75rem !default;
      $alert-padding-x: 1.25rem !default;
      position: relative;
      padding: $alert-padding-y $alert-padding-x;
      margin-bottom: 0;
    }
  }

  // Summary row
  // ---------------------------------------------------
  .datatable-summary-row {
    .datatable-body-row {
      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
}