/*  =Button
-------------------------------------------------------------- */
.btn {


}


// btn-plain (used for icons, etc.)
// ---------------------------------------------------
.btn-plain {
  line-height: normal;
  padding: 0;
  border: 0;

  &:focus {
    box-shadow: none;
  }
}

