/* You can add global styles to this file, and also import other style files */
@import "assets/scss/main";
@font-face {
  font-family: "museo sans", sans-serif;
  src: url(assets/fonts/MuseoSans_500.otf) format("opentype");
}

.modal-dialog {
  .modal-content {
    border: none !important;
    background: transparent;
  }
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

.scrollbar {
  overflow-y: scroll; /* Add the ability to scroll */
	scroll-behavior: smooth;
  background-color: #0d0d0d;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
ngb-datepicker.dropdown-menu {
  font-size: 16px;
  color: #ffffff !important;
  background-color: #333333;
  background-clip: padding-box;
  font-family: "Roboto", sans-serif !important;
}
.dropdown-menu.show {
  display: block;
  background: #333333;
  border: 1px solid #4c4d4f;
  box-sizing: border-box;
  border-radius: 8px;
}
.ngb-dp-months {
  display: flex;
  justify-content: center;
}
.btn-link {
  font-weight: 400;
  color: white !important;
  text-decoration: underline;
  box-shadow: none;
  overflow: none;
}
.btn-link:focus {
  box-shadow: none;
  outline: none;
}
.ngb-dp-header {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 5px;
  background: #ee05f2 !important;
  border-radius: 8px 8px 0px 0px;
  text-transform: uppercase;
}
.ngb-dp-weekday {
  line-height: 2rem;
  text-align: center;
  font-style: normal !important;
}

.bg-primary {
  background-color: #ee05f2 !important;
}

.btn-light {
  color: white !important;
  background-color: #f8f9fa;
}
.ngb-dp-weekdays {
  padding: 5px;
}

[ngbDatepickerDayView] {
  border-radius: 1rem;
}
.otp-input-field {
  .wrapper {
    .otp-input {
      border-radius: 4px;
      border: 2px solid;
      border-color: transparent;
      background: linear-gradient(black, black) padding-box,
        linear-gradient(90.54deg, #ee05f2 4.5%, #05aff2 142.44%) border-box;
      box-shadow: none;
      color: white;
      font-family: "Museo Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 22px;
      outline: none;
    }
  }
}
.otp-invalid {
  .wrapper {
    .otp-input {
      border-radius: 4px;
      border: 2px solid #de1e62;
      // background: linear-gradient(black, black) padding-box,
      //   linear-gradient(90.54deg, #de1e62 4.5%, #de1e62 142.44%) border-box;
      background-color: black;
      box-shadow: none;
      color: white;
      font-family: "Museo Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 22px;
      outline: none;
    }
  }
}
p {
  font-family: Lato, sans-serif;
}

.toast-top-center {
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* toast styles */
.toast-title {
  font-weight: bold;
  font-family: "Museo Sans", sans-serif;
}
.toast-message {
  word-wrap: break-word;
  font-family: "Museo Sans", sans-serif;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-container {
  pointer-events: none;
  position: fixed;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  padding: 15px 15px 15px 50px;
  width: 350px;
  height: 70px;
  border-radius: 2px;
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast-error {
  color: white;
  background: #bd362f;
}
.toast-success {
  color: white;
  background: #51a351;
}
.toast-warning {
  color: white;
  background: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 6px;
  background-color: white;
  opacity: 0.4;
}
.logout-icon {
  cursor: pointer;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

.logout-icon {
  cursor: pointer;
}
