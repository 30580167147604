// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

body {
  color: $gray-333;
  font-family: "Titillium Web", sans-serif;
  background-color: #dddddd;
}

// sticky footer
body > app-root,
body > app-root > app-layout,
body > app-root > app-login {
  height: 100%;
  flex-direction: column;
  display: flex;
}

// Print Css
// ---------------------------------------------------
@media print {
  body * {
    visibility: hidden;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible;
  }
  /*.section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }*/
}
