/**
 * Basic typography style for copy text
 */
//body {
//  color: $text-color;
//  font: normal 125% / 1.4 $text-font-stack;
//}


// Fonts
// ---------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,600;0,700;1,700&display=swap');


// @font-face {
//   font-family: 'Gotham';
//   src: url('../../fonts/Gotham-Light.eot');
//   src: url('../../assets/fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
//   url('../../assets/fonts/Gotham-Light.woff2') format('woff2'),
//   url('../../assets/fonts/Gotham-Light.woff') format('woff'),
//   url('../../assets/fonts/Gotham-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Gotham';
//   src: url('assets/fonts/Gotham-Book.eot');
//   src: url('assets/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
//   url('assets/fonts/Gotham-Book.woff2') format('woff2'),
//   url('assets/fonts/Gotham-Book.woff') format('woff'),
//   url('assets/fonts/Gotham-Book.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Gotham';
//   src: url('assets/fonts/Gotham-Bold.eot');
//   src: url('assets/fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
//   url('assets/fonts/Gotham-Bold.woff2') format('woff2'),
//   url('assets/fonts/Gotham-Bold.woff') format('woff'),
//   url('assets/fonts/Gotham-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }


// .gotham-d{
//   font-family: 'Gotham';
//   font-weight: 300 !important;
//   font-style: normal;
// }
// .gotham-n{
//   font-family: 'Gotham';
//   font-weight: normal !important;
//   font-style: normal;
// }
// .gotham-b{
//   font-family: 'Gotham';
//   font-weight: bold  !important;
//   font-style: normal;
// }


// Headings
// ---------------------------------------------------
h1, .h1 {font-size: 2rem;}
h3, .h3 {font-size: 1.5rem;}
h4, .h4 {font-size: 1.25rem}



// Font Sizes
// ---------------------------------------------------
.fz-10{
  font-size:10px;
}
.fz-11{
  font-size:11px;
}
.fz-12{
  font-size:12px;
}
.fz-13{
  font-size:13px;
}
.fz-14{
  font-size:14px;
}
.fz-15{
  font-size:15px;
}
.fz-16{
  font-size:16px;
}
.fz-17{
  font-size:17px;
}
.fz-18{
  font-size:18px;
}
.fz-19{
  font-size:19px;
}
.fz-20{
  font-size:20px;
}
.fz-21{
  font-size:21px;
}
.fz-22{
  font-size:22px;
}
.fz-23{
  font-size:23px;
}
.fz-24{
  font-size:24px;
}
.fz-25{
  font-size:25px;
}
.fz-26{
  font-size:26px;
}
.fz-27{
  font-size:27px;
}
.fz-28{
  font-size:28px;
}
.fz-29{
  font-size:29px;
}
.fz-30{
  font-size:30px;
}
.fz-31{
  font-size:31px;
}
.fz-32{
  font-size:32px;
}


