// just wrap entire file withing theme
//.company-3-theme {
/*  =Header
-------------------------------------------------------------- */
.header {
  
}

/*  =404
-------------------------------------------------------------- */
.page-not-found {

  width: fit-content;
  margin: 0 auto;
  text-align: center;
  margin-top: 150px;

  .btn{
    width: 290px;
    height: 44px;
    background-color: $primary;
    color: white;
    font-size: 12px;
    margin-top: 32px;
  }

}
