// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
//$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
//$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

/// Container's maximum width
/// @type Length
//$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
//$breakpoints: (
//  'small': 320px,
//  'medium': 768px,
//  'large': 1024px,
//) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';

$base-url: '' !default;
$base-url-images: "assets/images/" !default;

// $spacers
/*$spacer: 1rem !default;
$spacers: () !default;*/
// stylelint-disable-next-line scss/dollar-variable-default
/*$spacers: (0: 0,
        1: ($spacer * .25), //4px
        2: ($spacer * .5), //8px
        3: $spacer, //16px
        4: ($spacer * 1.25), //20px
        5: ($spacer * 1.5), //24px
        6: ($spacer * 1.5), //24px
        7: ($spacer * 2), //32px
        8: ($spacer * 2.25), //36px
        9: ($spacer * 3), //48px
);*/

// @colors
$orange: #F47B20 !default;
$orange-1:#F35D22 !default;
$cyan: #36B7C5 !default;

// Gray shades
$gray-cfc: #FCFCFC !default;
$gray-ddd: #DDDDDD !default;
$gray-a5a: #5A5A5A !default;

$white: #ffffff !default;
$gray-666: #666666 !default;
$gray-333: #333333 !default;

$gray-cfc: #FCFCFC !default;
$gray-083: #7E8083 !default;

// status colors#36B7C5
$red: #E32636 !default;
$red-1:#C00C00 !default;
$yellow: #E1AD01 !default;
$green: #6FCF97 !default;
$status-white: $gray-cfc !default;
$black: #040404 !default;
$dark-red: #76191C !default;
$purple: #7C47EB !default;
$blue: #2196F3 !default;


// Forms
$input-border-color: $gray-083 !default;

//$modal-backdrop-opacity: 0.6;

// Define colors' segment
$primary: $orange !default;
$secondary: $cyan !default;
$tertiary: $gray-083 !default;

// Spinners
$spinner-border-width-sm: 1px !default;





