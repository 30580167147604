// Colors
// ---------------------------------------------------
.text-primary {
  color: $primary;
}
.text-secondary {
  color: $secondary;
}
.text-tertiary {
  color: $tertiary;
}

.text-gray-cfc {
  color: $gray-cfc;
}
.text-gray-ddd {
  color: $gray-ddd;
}
.text-gray-a5a {
  color: $gray-a5a;
}
.text-gray-666 {
  color: $gray-666;
}
.text-gray-333 {
  color: $gray-333;
}
.text-gray-083 {
  color: $gray-083;
}
.text-white {
  color: $white;
}

.text-red {
  color: $red;
}
.text-yellow {
  color: $yellow;
}
.text-green {
  color: $green;
}
.text-status-white {
  color: $status-white;
}
.text-black {
  color: $black;
}
.text-dark-red {
  color: $dark-red;
}
.text-purple {
  color: $purple;
}
.text-blue {
  color: $blue;
}
.text-cyan {
  color: $cyan;
}

.text-Red {
  color: $red;
}
.text-Yellow {
  color: $yellow;
}
.text-Mustard {
  color: $yellow;
}
.text-Green {
  color: $green;
}
.text-Status-white {
  color: $status-white;
}
.text-Black {
  color: $black;
}
.text-Dark-red {
  color: $dark-red;
}
.text-Purple {
  color: $purple;
}
.text-Blue {
  color: $blue;
}
.text-Cyan {
  color: $cyan;
}
.text-Teal {
  color: $cyan;
}
.text-Orange {
  color: $orange-1;
}

// BGs
// ---------------------------------------------------
.bg-primary {
  background-color: #ee05f2 !important;
}
.bg-secondary {
  background-color: $secondary !important;
}
.bg-tertiary {
  background-color: $tertiary !important;
}

.bg-gray-cfc {
  background-color: $gray-cfc !important;
}
.bg-gray-ddd {
  background-color: $gray-ddd !important;
}
.bg-gray-a5a {
  background-color: $gray-a5a !important;
}
.bg-gray-666 {
  background-color: $gray-666 !important;
}
.bg-gray-333 {
  background-color: $gray-333 !important;
}
.bg-gray-083 {
  background-color: $gray-083 !important;
}
.bg-white {
  background-color: $white !important;
}

.bg-red {
  background-color: $red !important;
}
.bg-yellow {
  background-color: $yellow !important;
}
.bg-green {
  background-color: $green !important;
}
.bg-status-white {
  background-color: $status-white !important;
}
.bg-black {
  background-color: $black !important;
}
.bg-dark-red {
  background-color: $dark-red !important;
}
.bg-purple {
  background-color: $purple !important;
}
.bg-blue {
  background-color: $blue !important;
}
.bg-cyan {
  background-color: $cyan !important;
}
.bg-orange {
  background-color: $orange-1 !important;
}

.bg-Red {
  background-color: $red !important;
}
.bg-Yellow {
  background-color: $yellow !important;
}
.bg-Mustard {
  background-color: $yellow !important;
}
.bg-Green {
  background-color: $green !important;
}
.bg-Status-white {
  background-color: $status-white !important;
}
.bg-Black {
  background-color: $black !important;
}
.bg-Dark-red {
  background-color: $dark-red !important;
}
.bg-Purple {
  background-color: $purple !important;
}
.bg-Blue {
  background-color: $blue !important;
}
.bg-Cyan {
  background-color: $cyan !important;
}
.bg-Teal {
  background-color: $cyan !important;
}
.bg-Orange {
  background-color: $orange-1 !important;
}
.bg-White {
  background-color: $white !important;
}

// borders-colors
// ---------------------------------------------------
.border-primary {
  border-color: $primary !important;
}
.border-secondary {
  border-color: $secondary !important;
}
.border-tertiary {
  border-color: $tertiary !important;
}

.border-gray-cfc {
  border-color: $gray-cfc !important;
}
.border-gray-ddd {
  border-color: $gray-ddd !important;
}
.border-gray-a5a {
  border-color: $gray-a5a !important;
}
.border-gray-666 {
  border-color: $gray-666 !important;
}
.border-gray-333 {
  border-color: $gray-333 !important;
}
.border-white {
  border-color: $white !important;
}

.border-red {
  border-color: $red !important;
}
.border-yellow {
  border-color: $yellow !important;
}
.border-green {
  border-color: $green !important;
}
.border-status-white {
  border-color: $status-white !important;
}
.border-black {
  border-color: $black !important;
}
.border-dark-red {
  border-color: $dark-red !important;
}
.border-purple {
  border-color: $purple !important;
}
.border-blue {
  border-color: $blue !important;
}
.border-cyan {
  border-color: $cyan !important;
}

// border-sizes
// ---------------------------------------------------
.border-w-1 {
  border-width: 1px !important;
}
.border-w-2 {
  border-width: 2px !important;
}
.border-w-3 {
  border-width: 3px !important;
}
.border-w-4 {
  border-width: 4px !important;
}
.border-w-5 {
  border-width: 5px !important;
}
.border-w-6 {
  border-width: 6px !important;
}
.border-w-7 {
  border-width: 7px !important;
}
.border-w-8 {
  border-width: 8px !important;
}
.border-w-9 {
  border-width: 9px !important;
}
.border-w-10 {
  border-width: 10px !important;
}

// border-style
// ---------------------------------------------------
.border-solid {
  border-style: solid;
}

// Anchor
// ---------------------------------------------------
.list-cursor-pointer a,
.cursor-pointer {
  cursor: pointer;
}

// Misc
// ---------------------------------------------------
.rounded-2x {
  border-radius: 2px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.outline-none,
.outline-none:hover {
  outline: none;
}

// Margins
// ---------------------------------------------------
.mb-n1px {
  margin-bottom: -1px;
}

.mr-6 {
  margin-right: 32px;
}

.word-wrap {
  //white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

//padding
// -------------------------------------

.pt-1px {
  padding-top: 1px;
}
.pt-10px {
  padding-top: 10px;
}
.pb-10px {
  padding-bottom: 10px;
}
.plr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

//font weight
// -------------------------------------

.fw-400 {
  font-weight: 400;
}
.fw-600 {
  font-weight: 600;
}

//top
//----------------------------
.top-1 {
  top: 1px;
}
.top-2 {
  top: 2px;
}
.top-3 {
  top: 3px;
}
.top-4 {
  top: 4px;
}
.top-5 {
  top: 5px;
}
.top-10 {
  top: 10px;
}
.top-n8 {
  top: -8px;
}
.top-n15 {
  top: -15px;
}
.top-n21 {
  top: -21px;
}

//width
//--------------------------
.width-min {
  width: min-content;
}

//z-index
//--------------------

.z-i1 {
  z-index: 1;
}
.z-i2 {
  z-index: 2;
}

//
//-----------
.vh {
  visibility: hidden;
}
.hide {
  display: none;
}

// horizontalscroll div
// -----------------------
.hscrolldiv {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0px 12px;
  > div {
    display: inline-block;
    padding: 0px 8px;
  }
}

//
// ------------------------
.focus-no-outline {
  outline: none;
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

// grid colors
// ----------------------
.ag-row,
.driver-list-2 .header {
  &.Red {
    background-color: $red !important;
  }
  &.Blue {
    background-color: $blue !important;
  }
  &.Orange {
    background-color: $orange-1 !important;
  }
  &.Mustard {
    background-color: $yellow !important;
  }
  &.Green {
    background-color: $green !important;
  }
  &.Teal {
    background-color: $cyan !important;
  }
  &.White {
    background-color: $white !important;
  }
  &.Black {
    background-color: $gray-333 !important;
  }
  &.Purple {
    background-color: $purple !important;
  }
}
