.ic {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}

.ic.md {
  width: 16px;
  height: 16px;
  vertical-align: initial;
}
.ic.sm {
  width: 14px;
  height: 14px;
  vertical-align: initial;
}
.ic.xs {
  width: 10px;
  height: 10px;
  vertical-align: initial;
}

// .ic-hamburger {
//   background-image: url("./assets/images/icons/hamburger.svg");
// }
